"use strict";
function findElement(elementName) {
    return document.querySelector(elementName);
}
function setElementAnimation(element, animation) {
    element.style.animation = animation;
}
const burgerMenu = findElement(".fa-bars");
const slideInMenu = findElement(".mobileSlideInMenu");
const exitButton = findElement(".fa-times");
const mobileSlideMenu = findElement(".mobileSlideInMenu");
burgerMenu.addEventListener("click", setAnimateIn);
exitButton.addEventListener("click", setAnimateOut);
mobileSlideMenu.addEventListener("click", setAnimateOut);
function setAnimateOut() {
    slideInMenu.style.animation = "animate-out 1.2s ease-in-out forwards";
}
function setAnimateIn() {
    slideInMenu.style.animation = "animate-in 1.2s ease-in-out forwards";
    mobileSlideMenu.style.display = "";
}
mobileSlideMenu.style.display = "none";
