"use strict";
const myHeader = document.querySelector(".header");
const myButton = document.querySelector(".topbutton");
if (myHeader && myButton !== null) {
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.intersectionRatio > 0) {
                myButton.classList.remove("scrolledtopbutton");
                myHeader.classList.remove("scrolledHeader");
            }
            else {
                myButton.classList.add("scrolledtopbutton");
                myHeader.classList.add("scrolledHeader");
            }
        });
    });
    observer.observe(myHeader);
    observer.observe(myButton);
}
